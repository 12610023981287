import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cropper, { Area } from 'react-easy-crop';
import Modal from 'react-modal';
import styles from './Course.module.css';
import getCroppedImg from '../ImageCrop/cropImage';
import { APIResponse, ImageData } from '../API/types/Types';
import { fetchCourseData, fetchAllSections, updateCourse, uploadImage } from '../API/API';
import { CourseData } from '../API/types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Course: React.FC = () => {
  const { tableName } = useParams<{ tableName: string }>();
  const navigate = useNavigate();
  const [vocabularyLists, setVocabularyLists] = useState<string[]>([]);
  const [selectedIdentifier, setSelectedIdentifier] = useState<string>('');
  const [apiResponse, setApiResponse] = useState<APIResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [croppedImages, setCroppedImages] = useState<Blob[]>([]); // Temporary storage for cropped images
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [formData, setFormData] = useState<APIResponse>({
    Identifier: '',
    City: '',
    Country: '',
    Description: '',
    Images: [],
    Vocabulary_List: '',
  });
  const [isFormChanged, setIsFormChanged] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppingDialogOpen, setCroppingDialogOpen] = useState(false);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      if (!selectedIdentifier) return;
  
      try {
        const response = await fetchCourseData(selectedIdentifier);
  
        if (response) {
          const imagesArray = Array.isArray(response.Images?.L) ? response.Images.L : [];
          
          const parsedData: APIResponse = {
            Identifier: response.Identifier?.S || '',
            City: response.City?.S || '',
            Country: response.Country?.S || '',
            Description: response.Description?.S || '',
            Vocabulary_List: response.Vocabulary_List?.S || '',
            Images: imagesArray.map((image: any) => ({
              URL: image?.M?.URL?.S || '',
              Description: image?.M?.Description?.S || '',
            })) || [],
          };
  
          setApiResponse(parsedData);
          setFormData(parsedData);
          setIsFormChanged(false);
        } else {
          setError('Response is null or undefined.');
        }
      } catch (err: any) {
        console.log('Error fetching course data:', err);
        setError(err.message || 'An error occurred while fetching data.');
      }
    };
  
    if (selectedIdentifier) {
      fetchCourseDetails();
    }
  }, [selectedIdentifier]);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const sectionIdentifiers = await fetchAllSections();
        // Sort the section identifiers alphabetically before setting the state
        const sortedIdentifiers = sectionIdentifiers.sort((a, b) => a.localeCompare(b));
        setVocabularyLists(sortedIdentifiers);
      } catch (err) {
        console.log('Error fetching sections:', err);
      }
    };
  
    fetchSections();
  }, []);
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsFormChanged(true);
  };

  const handleIdentifierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedIdentifier(e.target.value);
    setIsFormChanged(true);
  };

  const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImageFiles(Array.from(e.target.files));
      setCurrentImageIndex(0);
      setCroppingDialogOpen(true);
      setIsFormChanged(true);
    }
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    
    const reorderedImages = Array.from(formData.Images);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);
    
    setFormData((prevData) => ({
      ...prevData,
      Images: reorderedImages,
    }));
    setIsFormChanged(true); // Mark form as changed
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    if (imageFiles[currentImageIndex] && croppedAreaPixels) {
      try {
        // Crop the image and get the Blob
        const croppedBlob = await getCroppedImg(URL.createObjectURL(imageFiles[currentImageIndex]), croppedAreaPixels);
        const randomString = generateRandomString(6);
        const fileName = `${formData.City}_image_${randomString}${imageFiles[currentImageIndex].name.substring(imageFiles[currentImageIndex].name.lastIndexOf('.'))}`;
  
        // Upload the cropped image and get its URL
        const uploadedImageUrl = await uploadImage(fileName, croppedBlob);
  
        // Add the new image URL to the formData
        const newImage = {
          URL: uploadedImageUrl,
          Description: '', // Default empty description for now
        };
  
        setFormData((prevData) => ({
          ...prevData,
          Images: [...prevData.Images, newImage], // Add the new image to the Images array
        }));
  
        // Move to the next image or close the cropping dialog
        if (currentImageIndex < imageFiles.length - 1) {
          setCurrentImageIndex(currentImageIndex + 1);
        } else {
          setCroppingDialogOpen(false);
        }
  
        setIsFormChanged(true);
      } catch (error) {
        console.log('Error cropping or uploading image:', error);
      }
    }
  };
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    try {
      // Prepare formData to match CourseData structure
      const transformedData: CourseData = {
        Identifier: formData.Identifier,
        City: formData.City,
        Country: formData.Country,
        Description: formData.Description,
        Images: formData.Images.map((image) => ({
          URL: image.URL, // Assign URL from uploaded images
          Description: image.Description // Assign description from form
        })),
        Vocabulary_List: formData.Vocabulary_List || undefined, // Optional, default to undefined if not present
      };
  
      // Call the updateCourse function with the transformed data
      await updateCourse(transformedData);
      setError(null);
      setIsFormChanged(false);
    } catch (err: any) {
      console.log('Error during save:', err);
      setError(err.response ? err.response.data.error : err.message);
    }
  };
  

  const renderImages = () => {
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="imagesGrid">
          {(provided) => (
            <div
              className={styles.imagesGrid}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {formData.Images.map((img, index) => (
                <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                  {(provided) => (
                    <div
                      className={styles.imageWrapper}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <img src={img.URL} alt={img.Description} className={styles.image} />
                      <textarea
                        placeholder="Description"
                        value={img.Description}
                        onChange={(e) => {
                          const newImages = [...formData.Images];
                          newImages[index].Description = e.target.value;
                          setFormData((prevData) => ({
                            ...prevData,
                            Images: newImages,
                          }));
                        }}
                        className={styles.descriptionTextarea}
                      />
                      <button
                        type="button"
                        className={styles.deleteButton}
                        onClick={() => handleDeleteImage(index)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const handleDeleteImage = (index: number) => {
    const newImages = formData.Images.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      Images: newImages,
    }));
    setIsFormChanged(true); // Mark the form as changed so the user can save the changes
  };

  if (error) {
    return <div className={styles.container}><p>Error: {error}</p></div>;
  }

  return (
    <div className={styles.container}>
      <button onClick={() => navigate('/')} className={styles.backButton}>&larr; Back to Home</button>
      <h1 className={styles.title}>Course</h1>

      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Identifier:
            <select name="Identifier" value={selectedIdentifier} onChange={handleIdentifierChange} className={styles.input}>
              <option value="">Select Identifier</option>
              {Array.from({ length: 150 }, (_, i) => i + 1).map((num) => (
                <option key={num} value={num.toString()}>{num}</option>
              ))}
            </select>
          </label>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Vocabulary List:
            <select name="Vocabulary_List" value={formData.Vocabulary_List} onChange={handleChange} className={styles.input}>
              <option value="">Select Vocabulary List</option>
              {vocabularyLists.map((list) => (
                <option key={list} value={list}>{list}</option>
              ))}
            </select>
          </label>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            City:
            <input type="text" name="City" value={formData.City} onChange={handleChange} className={styles.input} />
          </label>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Country:
            <input type="text" name="Country" value={formData.Country} onChange={handleChange} className={styles.input} />
          </label>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Description:
            <textarea name="Description" value={formData.Description} onChange={handleChange} className={styles.textarea} />
          </label>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Images:
            <input type="file" accept="image/*" onChange={handleFileChange} multiple className={styles.input} />
          </label>
          <div>
            {renderImages()}
          </div>
        </div>
        {isFormChanged && (
          <button type="submit" className={styles.saveButton}>Save</button>
        )}
      </form>

      <Modal isOpen={croppingDialogOpen} onRequestClose={() => setCroppingDialogOpen(false)}>
        {imageFiles[currentImageIndex] && (
          <div className={styles.cropContainer}>
            <Cropper
              image={URL.createObjectURL(imageFiles[currentImageIndex])}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
            <button onClick={handleCrop} className={`${styles.button} ${styles.cropButton}`}>Crop Image</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Course;
