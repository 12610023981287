import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Homepage.module.css';

const HomePage = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.headerTitle}>Fluency Data Add</h1>
      <div className={styles.columns}>
        <div className={styles.column} onClick={() => handleNavigate('/vocabulary')}>
          <h2 className={styles.title}>Vocabulary</h2>
        </div>
        <div className={styles.column} onClick={() => handleNavigate('/passages')}>
          <h2 className={styles.title}>Passages</h2>
        </div>
      </div>
      <div className={styles.columns}>
        <div className={styles.column} onClick={() => handleNavigate('/course')}>
          <h2 className={styles.title}>Course</h2>
        </div>
        <div className={styles.column} onClick={() => handleNavigate('/lessons')}>
          <h2 className={styles.title}>Lessons</h2>
        </div>
      </div>
      <div className={styles.columns}>
        <div className={styles.column} onClick={() => handleNavigate('/metrics')}>
          <h2 className={styles.title}>Metrics</h2>
        </div>
        <div className={styles.column} onClick={() => handleNavigate('/trivia')}>
          <h2 className={styles.title}>Trivia</h2>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
