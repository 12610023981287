// src/components/VocabularyDetails.tsx

import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "./Vocabulary.module.css";
import Cropper, { Area } from "react-easy-crop";
import Modal from "react-modal";
import getCroppedImg from "../ImageCrop/cropImage";
import {
  fetchCourseData,
  fetchVocabularyData,
  addVocabulary,
  updateVocabulary,
  deleteVocabulary,
  uploadImage,
  callOpenAI, // Import callOpenAI function
} from "../API/API";
import { VocabularyData } from "../API/types";

const VocabularyDetails: React.FC = () => {
  const { tableName } = useParams<{ tableName: string }>();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<VocabularyData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [editingRow, setEditingRow] = useState<string | null>(null);
  const [formData, setFormData] = useState<VocabularyData>({
    Identifier: uuidv4(),
    Level: "1",
    Section: "",
    SpanishWord: "",
    EnglishWord: "",
    ImageUrl: "",
    SpanishOptions: ["", "", ""],
    EnglishOptions: ["", "", ""],
  });

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppingDialogOpen, setCroppingDialogOpen] = useState(false);
  const [croppedBlob, setCroppedBlob] = useState<File | null>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false); // State for loading indicator
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false); // State for image upload
  const [editingImageIdentifier, setEditingImageIdentifier] = useState<string | null>(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCourseData(formData.Level);
        const vocabularyList = response.Vocabulary_List?.S || "";

        setFormData((prevData) => ({
          ...prevData,
          Section: vocabularyList,
        }));
      } catch (err: any) {
        console.log("Error fetching course data:", err);
        setError(err.message);
        setTableData([]); // Clear the table data on error
      }
    };

    if (formData.Level) {
      fetchData();
    }
  }, [formData.Level]);

  useEffect(() => {
    const fetchVocabulary = async () => {
      try {
        const items = await fetchVocabularyData(formData.Section);
        setTableData(items);
      } catch (err: any) {
        console.log("Error fetching vocabulary data:", err);
        setError(err.message);
        setTableData([]); // Clear the table data on error
      }
    };

    if (formData.Section) {
      fetchVocabulary();
    }
  }, [formData.Section, tableName]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    identifier?: string
  ) => {
    const { name, value } = e.target;
    const [key, index] = name.split("_");
  
    if (identifier) {
      // Update the specific item in tableData
      setTableData((prevData) =>
        prevData.map((item) =>
          item.Identifier === identifier
            ? {
                ...item,
                [key === "SpanishOptions" || key === "EnglishOptions"
                  ? key
                  : name]:
                  key === "SpanishOptions" || key === "EnglishOptions"
                    ? item[key as "SpanishOptions" | "EnglishOptions"].map(
                        (opt, idx) =>
                          idx === parseInt(index, 10) ? value : opt
                      )
                    : value,
              }
            : item
        )
      );
    } else {
      // Update the formData
      if (key === "SpanishOptions" || key === "EnglishOptions") {
        setFormData((prevData) => {
          const optionsKey =
            key === "SpanishOptions" ? "SpanishOptions" : "EnglishOptions";
  
          const newOptions = [
            ...prevData[optionsKey as "SpanishOptions" | "EnglishOptions"],
          ];
  
          newOptions[parseInt(index, 10)] = value;
  
          return {
            ...prevData,
            [optionsKey]: newOptions,
          };
        });
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };
  

  const handleGenerateVocabulary = async () => {
    setIsGenerating(true);
    setError(null);

    try {
      const { SpanishWord, EnglishWord } = formData;

      if (!SpanishWord.trim() || !EnglishWord.trim()) {
        setError("Please provide both Spanish and English words.");
        setIsGenerating(false);
        return;
      }

      const openaiPrompt = `Generate three multiple-choice options (not including the correct answer) for the following Spanish word and its English translation.

Spanish Word: ${SpanishWord}
English Translation: ${EnglishWord}

Provide the options in JSON format with the following structure:

{
  "SpanishOptions": ["Option A", "Option B", "Option C"],
  "EnglishOptions": ["Option A", "Option B", "Option C"]
}

Ensure that the correct answer is included in the options. Only output the JSON response, and nothing else.`;

      const response = await callOpenAI(openaiPrompt);

      if (response && response.response) {
        const responseText = response.response;

        // Try to parse the JSON from the response
        try {
          const jsonStart = responseText.indexOf("{");
          const jsonEnd = responseText.lastIndexOf("}");
          const jsonString = responseText.substring(jsonStart, jsonEnd + 1);

          const generatedData = JSON.parse(jsonString);

          setFormData((prevData) => ({
            ...prevData,
            SpanishOptions: generatedData.SpanishOptions || ["", "", ""],
            EnglishOptions: generatedData.EnglishOptions || ["", "", ""],
          }));
        } catch (err) {
          console.error("Error parsing JSON:", err);
          setError("Error parsing response. Please ensure the prompt was clear.");
        }
      } else {
        setError("No response from OpenAI. Please try again.");
      }
    } catch (err) {
      console.error("Error generating vocabulary options:", err);
      setError("Error generating vocabulary options. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      let imageUrl = formData.ImageUrl;

      if (croppedBlob) {
        const newFileName = `${formData.EnglishWord.replace(
          /\s+/g,
          "_"
        )}_Spanish${imageFile!.name.substring(
          imageFile!.name.lastIndexOf(".")
        )}`;

        imageUrl = await uploadImage(newFileName, croppedBlob);
      }

      const formattedData = {
        ...formData,
        ImageUrl: imageUrl,
        SpanishOptions: formData.SpanishOptions.filter(
          (option) => option.trim() !== ""
        ),
        EnglishOptions: formData.EnglishOptions.filter(
          (option) => option.trim() !== ""
        ),
      };

      await addVocabulary(formattedData);

      setTableData([formattedData, ...tableData]);

      setError(null);

      // Reset other fields but keep Level and Section unchanged
      setFormData({
        Identifier: uuidv4(),
        Level: formData.Level,
        Section: formData.Section,
        SpanishWord: "",
        EnglishWord: "",
        ImageUrl: "",
        SpanishOptions: ["", "", ""],
        EnglishOptions: ["", "", ""],
      });

      setImageFile(null);
      setCroppedBlob(null);
    } catch (err: any) {
      console.log("Error submitting form:", err);
      setError(err.message);
    }
  };

  const handleEditSubmit = async (identifier: string, level: string) => {
    const itemToUpdate = tableData.find(
      (item) => item.Identifier === identifier
    );
  
    if (!itemToUpdate) {
      setError("Item not found for editing");
      return;
    }
  
    try {
      const updatedData = {
        ...itemToUpdate,
        SpanishOptions: itemToUpdate.SpanishOptions.filter(
          (option) => option.trim() !== ""
        ),
        EnglishOptions: itemToUpdate.EnglishOptions.filter(
          (option) => option.trim() !== ""
        ),
      };
  
      await updateVocabulary(identifier, level, updatedData);
  
      setEditingRow(null);
  
      setTableData((prevData) =>
        prevData.map((item) =>
          item.Identifier === identifier ? { ...item, ...updatedData } : item
        )
      );
    } catch (err: any) {
      console.log("Error updating item:", err);
      setError(err.message);
    }
  };
  

  const handleDelete = async (identifier: string, level: string) => {
    const itemToDelete = tableData.find(
      (item) => item.Identifier === identifier
    );

    if (!itemToDelete) {
      setError("Item not found for deletion");
      return;
    }

    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await deleteVocabulary({
          Identifier: identifier,
          Level: level,
          Section: itemToDelete.Section,
          SpanishWord: itemToDelete.SpanishWord,
          EnglishWord: itemToDelete.EnglishWord,
          ImageUrl: itemToDelete.ImageUrl,
          SpanishOptions: itemToDelete.SpanishOptions,
          EnglishOptions: itemToDelete.EnglishOptions,
        });

        setTableData((prevData) =>
          prevData.filter(
            (item) => item.Identifier !== identifier || item.Level !== level
          )
        );
      } catch (err: any) {
        console.log("Error deleting item:", err);
        setError(err.message);
      }
    }
  };

  const handleEdit = (identifier: string) => {
    setEditingRow(identifier);
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    identifier?: string
  ) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
      setCroppingDialogOpen(true);
      setEditingImageIdentifier(identifier || null);
    }
  };
  

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCrop = async () => {
    if (imageFile && croppedAreaPixels) {
      try {
        const croppedBlob = await getCroppedImg(
          URL.createObjectURL(imageFile),
          croppedAreaPixels
        );
  
        const fileExtension = imageFile.name.substring(
          imageFile.name.lastIndexOf(".")
        );
        const newFileName = `${formData.EnglishWord.replace(
          /\s+/g,
          "_"
        )}_Image_${uuidv4()}${fileExtension}`;
  
        setIsUploadingImage(true);
  
        const imageUrl = await uploadImage(newFileName, croppedBlob);
  
        if (editingImageIdentifier) {
          // Update the specific item's ImageUrl
          setTableData((prevData) =>
            prevData.map((item) =>
              item.Identifier === editingImageIdentifier
                ? { ...item, ImageUrl: imageUrl }
                : item
            )
          );
        } else {
          // Update formData's ImageUrl
          setFormData((prevData) => ({
            ...prevData,
            ImageUrl: imageUrl,
          }));
        }
  
        setIsUploadingImage(false);
        setCroppingDialogOpen(false);
      } catch (err: any) {
        console.error("Error cropping or uploading image:", err);
        setError("Error processing the image. Please try again.");
        setIsUploadingImage(false);
      }
    }
  };
  

  const renderCellValue = (value: any) => {
    if (Array.isArray(value)) {
      return value.map((option, index) => <div key={index}>{option}</div>);
    }

    return String(value);
  };

  return (
    <div className={styles.container}>
      <button onClick={() => navigate("/")} className={styles.backButton}>
        &larr; Back to Home
      </button>

      <h1 className={styles.title}>Vocabulary</h1>

      <div className={styles.form}>
        <div className={styles.topRight}>
          <label className={styles.label}>
            Level:
            <select
              name="Level"
              value={formData.Level}
              onChange={handleChange}
              className={styles.input}
            >
              {[...Array(150).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </label>

          <label className={styles.label}>
            Section:
            <input
              type="text"
              name="Section"
              value={formData.Section}
              readOnly
              className={styles.input}
            />
          </label>
        </div>
      </div>

      <h2>Add New Vocabulary</h2>

      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Spanish Word:
            <input
              type="text"
              name="SpanishWord"
              value={formData.SpanishWord}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </label>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            English Word:
            <input
              type="text"
              name="EnglishWord"
              value={formData.EnglishWord}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </label>
        </div>

        {/* Generate Options Button */}
        <button
          type="button"
          onClick={handleGenerateVocabulary}
          className={styles.button}
          disabled={isGenerating}
        >
          {isGenerating ? "Generating..." : "Generate Options"}
        </button>

        {/* Image Upload */}
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Image:
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className={styles.input}
            />
          </label>
        </div>

        {/* Image Preview */}
        {isUploadingImage && <p>Uploading image...</p>}
        {formData.ImageUrl && (
          <img
            src={formData.ImageUrl}
            alt="Uploaded"
            className={styles.image}
          />
        )}

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Spanish Options:
            <div className={styles.optionsGroup}>
              {formData.SpanishOptions.map((option, index) => (
                <input
                  key={index}
                  type="text"
                  name={`SpanishOptions_${index}`}
                  value={option}
                  onChange={handleChange}
                  className={styles.input}
                />
              ))}
            </div>
          </label>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            English Options:
            <div className={styles.optionsGroup}>
              {formData.EnglishOptions.map((option, index) => (
                <input
                  key={index}
                  type="text"
                  name={`EnglishOptions_${index}`}
                  value={option}
                  onChange={handleChange}
                  className={styles.input}
                />
              ))}
            </div>
          </label>
        </div>

        <button type="submit" className={styles.button}>
          Add Vocabulary
        </button>
      </form>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Identifier</th>
            <th>Section</th>
            <th>Spanish Word</th>
            <th>English Word</th>
            <th>Image</th>
            <th>Spanish Options</th>
            <th>English Options</th>
            <th>Edit / Delete</th>
          </tr>
        </thead>

        <tbody>
  {!formData.Section || tableData.length === 0 ? (
    <tr>
      <td colSpan={8} className={styles.noDataMessage}>
        {formData.Section
          ? "No vocabulary words found for this section."
          : "No section available for this level."}
      </td>
    </tr>
  ) : (
    tableData.map((item, index) => (
      <tr key={index}>
        <td>{item.Identifier}</td>
        {/* Editable fields */}
        <td>
          {editingRow === item.Identifier ? (
            <input
              type="text"
              name="Section"
              value={item.Section}
              onChange={(e) => handleChange(e, item.Identifier)}
              className={styles.input}
            />
          ) : (
            item.Section
          )}
        </td>
        <td>
          {editingRow === item.Identifier ? (
            <input
              type="text"
              name="SpanishWord"
              value={item.SpanishWord}
              onChange={(e) => handleChange(e, item.Identifier)}
              className={styles.input}
            />
          ) : (
            item.SpanishWord
          )}
        </td>
        <td>
          {editingRow === item.Identifier ? (
            <input
              type="text"
              name="EnglishWord"
              value={item.EnglishWord}
              onChange={(e) => handleChange(e, item.Identifier)}
              className={styles.input}
            />
          ) : (
            item.EnglishWord
          )}
        </td>
        <td>
          {editingRow === item.Identifier ? (
            <>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, item.Identifier)}
                className={styles.input}
              />
              {item.ImageUrl && (
                <img
                  src={item.ImageUrl}
                  alt={item.SpanishWord}
                  className={styles.image}
                />
              )}
            </>
          ) : item.ImageUrl ? (
            <img
              src={item.ImageUrl}
              alt={item.SpanishWord}
              className={styles.image}
            />
          ) : (
            "No Image"
          )}
        </td>
        <td>
          {editingRow === item.Identifier ? (
            <div className={styles.optionsGroup}>
              {item.SpanishOptions.map((option, idx) => (
                <input
                  key={idx}
                  type="text"
                  name={`SpanishOptions_${idx}`}
                  value={option}
                  onChange={(e) => handleChange(e, item.Identifier)}
                  className={styles.input}
                />
              ))}
            </div>
          ) : (
            renderCellValue(item.SpanishOptions)
          )}
        </td>
        <td>
          {editingRow === item.Identifier ? (
            <div className={styles.optionsGroup}>
              {item.EnglishOptions.map((option, idx) => (
                <input
                  key={idx}
                  type="text"
                  name={`EnglishOptions_${idx}`}
                  value={option}
                  onChange={(e) => handleChange(e, item.Identifier)}
                  className={styles.input}
                />
              ))}
            </div>
          ) : (
            renderCellValue(item.EnglishOptions)
          )}
        </td>
        <td>
          {editingRow === item.Identifier ? (
            <>
              <button
                onClick={() => handleEditSubmit(item.Identifier, item.Level)}
                className={styles.button}
              >
                Submit
              </button>
              <button
                onClick={() => setEditingRow(null)}
                className={styles.button}
              >
                Cancel
              </button>
            </>
          ) : (
            <div className={styles.buttonContainer}>
              <button
                onClick={() => handleEdit(item.Identifier)}
                className={styles.button}
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(item.Identifier, item.Level)}
                className={styles.button}
              >
                Delete
              </button>
            </div>
          )}
        </td>
      </tr>
    ))
  )}
</tbody>


      </table>

      {/* Cropping Modal */}
      <Modal
        isOpen={croppingDialogOpen}
        onRequestClose={() => setCroppingDialogOpen(false)}
        contentLabel="Crop Image"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        {imageFile && (
          <div className={styles.cropContainer}>
            <Cropper
              image={URL.createObjectURL(imageFile)}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />

            <div className={styles.cropControls}>
              <input
                type="range"
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(Number(e.target.value))}
                className={styles.zoomSlider}
              />
              <button
                onClick={handleCrop}
                className={`${styles.button} ${styles.cropButton}`}
                disabled={isUploadingImage}
              >
                {isUploadingImage ? "Uploading..." : "Crop & Upload Image"}
              </button>
            </div>
          </div>
        )}
      </Modal>

      {error && <div className={styles.error}>Error: {error}</div>}
    </div>
  );
};

export default VocabularyDetails;
