// src/components/TableDetails.tsx

import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "./Passages.module.css";
import Cropper, { Area } from "react-easy-crop";
import Modal from "react-modal";
import getCroppedImg from "../ImageCrop/cropImage";
import {
  fetchCourseData,
  fetchPassages,
  fetchVocabularyData,
  uploadImage,
  addPassage,
  updatePassage,
  deletePassage,
  callOpenAI,
} from "../API/API";
import { TableData } from "../API/types";

const TableDetails: React.FC = () => {
  const { tableName } = useParams<{ tableName: string }>();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [vocabularyList, setVocabularyList] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppingDialogOpen, setCroppingDialogOpen] = useState(false);
  const [croppedBlob, setCroppedBlob] = useState<Blob | null>(null);
  const [editingRow, setEditingRow] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isGeneratingTopic, setIsGeneratingTopic] = useState<boolean>(false);
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);

  const initialFormData: TableData = {
    Identifier: uuidv4(),
    Level: "1",
    Section: "",
    Genre: "",
    Topic: "",
    ProficiencyLevel: "1",
    Name: "",
    Description: "",
    Passage: "",
    Question_1: "",
    Answer_1: "",
    Options_1: ["", "", "", ""],
    Question_2: "",
    Answer_2: "",
    Options_2: ["", "", "", ""],
    Question_3: "",
    Answer_3: "",
    Options_3: ["", "", "", ""],
    Question_4: "",
    Answer_4: "",
    Options_4: ["", "", "", ""],
    ImageUrl: "",
    Prompt: ""
  };

  const [formData, setFormData] = useState<TableData>(initialFormData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sectionsData = await fetchCourseData(formData.Level);

        // Extract the section name from sectionsData
        const vocabularyListName = sectionsData.Vocabulary_List.S;

        // Update formData.Section with the section name
        setFormData((prevData) => ({
          ...prevData,
          Section: vocabularyListName,
        }));

        // Fetch passages using the vocabularyList
        const passages = await fetchPassages(vocabularyListName);

        setTableData(passages);
        setError(null);
      } catch (err: any) {
        console.log("Error fetching data:", err);
        setError(err.message);
        setTableData([]);
      }
    };

    if (formData.Level) {
      fetchData();
    }
  }, [formData.Level]);

  useEffect(() => {
    const fetchVocabulary = async () => {
      try {
        const items = await fetchVocabularyData(formData.Section);
        const spanishWords = items.map((item: any) => item.SpanishWord);
        setVocabularyList(spanishWords);
      } catch (err: any) {
        console.log("Error fetching vocabulary data:", err);
        setError(err.message);
        setVocabularyList([]); // Clear the vocabulary list on error
      }
    };

    if (formData.Section) {
      fetchVocabulary();
    }
  }, [formData.Section]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    identifier?: string
  ) => {
    const { name, value } = e.target;

    if (name === "Level" || name === "ProficiencyLevel") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (identifier) {
      // Handle changes in table data (for editing existing entries)
      setTableData((prevData) =>
        prevData.map((item) => {
          if (item.Identifier === identifier) {
            if (name.startsWith("Options_")) {
              const [optionsKey, indexStr] = name.split("_");
              const optionsIndex = parseInt(indexStr, 10);
              const optionsArrayKey = optionsKey as keyof TableData;
              return {
                ...item,
                [optionsArrayKey]: (item[optionsArrayKey] as string[]).map(
                  (opt, idx) => (idx === optionsIndex ? value : opt)
                ),
              };
            } else {
              return {
                ...item,
                [name]: value,
              };
            }
          } else {
            return item;
          }
        })
      );
    } else {
      // Handle changes in formData
      if (name.startsWith("Options_")) {
        const [optionsKey, indexStr] = name.split("_");
        const optionsIndex = parseInt(indexStr, 10);
        const optionsArrayKey = optionsKey as keyof TableData;
        setFormData((prevData) => ({
          ...prevData,
          [optionsArrayKey]: (prevData[optionsArrayKey] as string[]).map(
            (opt, idx) => (idx === optionsIndex ? value : opt)
          ),
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
      setCroppingDialogOpen(true);
    }
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCrop = async () => {
    if (imageFile && croppedAreaPixels) {
      try {
        const croppedBlob = await getCroppedImg(
          URL.createObjectURL(imageFile),
          croppedAreaPixels
        );

        setCroppedBlob(croppedBlob);
        setCroppingDialogOpen(false);

        // Generate a sanitized file name
        const fileExtension = imageFile.name.substring(
          imageFile.name.lastIndexOf(".")
        );
        const newFileName = `${formData.Name.replace(
          /\s+/g,
          "_"
        )}_Image_${uuidv4()}${fileExtension}`;

        setIsUploadingImage(true);
        // Upload the cropped image and get the URL
        const imageUrl = await uploadImage(newFileName, croppedBlob);
        setFormData((prevData) => ({
          ...prevData,
          ImageUrl: imageUrl,
        }));
        setIsUploadingImage(false);
      } catch (err: any) {
        console.error("Error cropping or uploading image:", err);
        setError("Error processing the image. Please try again.");
        setIsUploadingImage(false);
      }
    }
  };

  const handleGenerateRandomTopic = async () => {
    setIsGeneratingTopic(true);
    setError(null);

    try {
      const { Genre } = formData;

      if (!Genre.trim()) {
        setError("Please provide a Genre before generating a topic.");
        setIsGeneratingTopic(false);
        return;
      }

      const openaiPrompt = `Please provide a random topic or theme suitable for a story in the genre of "${Genre}". Return only the topic or theme in one sentence without any additional text. Return all text in Spanish!`;

      const response = await callOpenAI(openaiPrompt);

      if (response && response.response) {
        const topic = response.response.trim().replace(/^"|"$/g, "");

        setFormData((prevData) => ({
          ...prevData,
          Topic: topic,
        }));
      } else {
        setError("No response from OpenAI. Please try again.");
      }
    } catch (err) {
      console.error("Error generating topic:", err);
      setError("Error generating topic. Please try again.");
    } finally {
      setIsGeneratingTopic(false);
    }
  };

  const handleGeneratePassage = async () => {
    setIsGenerating(true);
    setError(null);

    try {
      const { Genre, Topic, ProficiencyLevel } = formData;

      if (!Genre.trim() || !Topic.trim()) {
        setError("Please provide both Genre and Topic.");
        setIsGenerating(false);
        return;
      }

      const levelDescriptions: { [key: string]: string } = {
        "1": "user that is incredibly new to the language and only can understand a few greetings. Can only understand present tense.",
        "2": "user that is incredibly new to the language and only can understand a few greetings and very basic words. Can only understand present tense.",
        "3": "user that is very new to the langauge and is learning basic verbs, questions, ser and estar. Can only understand present tense and and is learning the words for locations, food, transportation, colors and adjectives. They have not learned past or future tense.",
        "4": "user that is new to the langauge and is learning present progressive and present tense. They only understand basic verbs, adjectives and nouns. They are learning the words for locations, ir, periphrastic future, tener. They have not learned past or future tense.",
        "5": `user that is new to the language and is learning emotions, common items, expressing opinions. These are the topics they are learning: Emotions
          Common Items
          To be able to,
          Opinions,
          Better & Worse,
          Thinking,
          Weather & Nature,
          Household items & Furniture,
          Entertainment & Free time,
          Some more verbs,
 and these are the ones they have learned Greetings,
Useful Phrases,
Useful Phrases: Part 2,
Masculine vs Feminine,
Plural vs Singular,
Pronombres,
Verbos principales,
Verbos Regulares,
Regular Verbs,
Interrogatives,
To be (Permanent),
To be (Temporary),
Present Progressive,
Transportation,
Colors,
Adjectives,
Possessive Pronouns,
Possessive Adjective,
To want,
Food & Drink 1,
To want Part two,
Places,
To go,
Going to do,
To Have,
Tener + verb,
Numbers 1-10,
Numbers 11-100,
Clothing,

        `,
        "6": `user that is new to the langauge that is learning these sections : To know
Clothes
Travel
Commands & Requests
Body parts
Hobbies
Technology
Months and have learned these sections Greetings
Useful Phrases
Useful Phrases: Part 2
Masculine vs Feminine
Plural vs Singular
Pronombres
Verbos principales
Verbos Regulares
Regular Verbs
Interrogatives
To be (Permanent)
To be (Temporary)
Present Progressive
Transportation
Colors
Adjectives
Possessive Pronouns
Possessive Adjective
To want
Food & Drink 1
To want Part two
Places
To go
Going to do
To Have
Tener + verb
Numbers 1-10
Numbers 11-100
Clothing
Emotions
Common Items
To be able to
Opinions
Better & Worse
Thinking
Weather & Nature
Household items & Furniture
Entertainment & Free time
Some more verbs.
Write the story so that the user can understand the passage based off of what they have learned and what they are learning.
`,
        "7": `A user that is a novice to the langauge that is learning these sections: Past tense - Preterite
More Preterite
Some More Preterite
Even More Preterite
Conditional
Story telling
Past tense - Imperfect
More Imperfect
Some More Imperfect
Even More Imperfect
and have learned these sections: Greetings
Useful Phrases
Useful Phrases: Part 2
Masculine vs Feminine
Plural vs Singular
Pronombres
Verbos principales
Verbos Regulares
Regular Verbs
Interrogatives
To be (Permanent)
To be (Temporary)
Present Progressive
Transportation
Colors
Adjectives
Possessive Pronouns
Possessive Adjective
To want
Food & Drink 1
To want Part two
Places
To go
Going to do
To Have
Tener + verb
Numbers 1-10
Numbers 11-100
Clothing
Emotions
Common Items
To be able to
Opinions
Better & Worse
Thinking
Weather & Nature
Household items & Furniture
Entertainment & Free time
Some more verbs
To know
Clothes
Travel
Commands & Requests
Body parts
Hobbies
Technology
Months. Write the story so that the user can understand the passage based off of what they have learned and what they are learning.
`,
        "8": `A user that is a novice to the langauge that is learning these sections: Cooking & Kitchen
Holidays and Celebrations
Formal
Professions
Reflexive verbs
Shopping & Money
School
Daily Routine
Verbs… Again. And has completed these sections: Greetings
Useful Phrases
Useful Phrases: Part 2
Masculine vs Feminine
Plural vs Singular
Pronombres
Verbos principales
Verbos Regulares
Regular Verbs
Interrogatives
To be (Permanent)
To be (Temporary)
Present Progressive
Transportation
Colors
Adjectives
Possessive Pronouns
Possessive Adjective
To want
Food & Drink 1
To want Part two
Places
To go
Going to do
To Have
Tener + verb
Numbers 1-10
Numbers 11-100
Clothing
Emotions
Common Items
To be able to
Opinions
Better & Worse
Thinking
Weather & Nature
Household items & Furniture
Entertainment & Free time
Some more verbs
To know
Clothes
Travel
Commands & Requests
Body parts
Hobbies
Technology
Months
Past tense - Preterite
More Preterite
Some More Preterite
Even More Preterite
Conditional
Story telling
Past tense - Imperfect
More Imperfect
Some More Imperfect
Even More Imperfect.
Write the story so that the user can understand the passage based off of what they have learned and what they are learning.
`,
        "9": `A user that is intermediate in the langauge that is learning these sections: Conditional mood - I would like
Body parts
Physical descriptions
Sports and fitness
Por vs para
More foods
Verbs pt.4
Nature
Society
Future tense
Future tense practice
More future practice.
 And has completed these sections: Greetings
Useful Phrases
Useful Phrases: Part 2
Masculine vs Feminine
Plural vs Singular
Pronombres
Verbos principales
Verbos Regulares
Regular Verbs
Interrogatives
To be (Permanent)
To be (Temporary)
Present Progressive
Transportation
Colors
Adjectives
Possessive Pronouns
Possessive Adjective
To want
Food & Drink 1
To want Part two
Places
To go
Going to do
To Have
Tener + verb
Numbers 1-10
Numbers 11-100
Clothing
Emotions
Common Items
To be able to
Opinions
Better & Worse
Thinking
Weather & Nature
Household items & Furniture
Entertainment & Free time
Some more verbs
To know
Clothes
Travel
Commands & Requests
Body parts
Hobbies
Technology
Months
Past tense - Preterite
More Preterite
Some More Preterite
Even More Preterite
Conditional
Story telling
Past tense - Imperfect
More Imperfect
Some More Imperfect
Even More Imperfect
Cooking & Kitchen
Holidays and Celebrations
Formal
Professions
Reflexive verbs
Shopping & Money
School
Daily Routine
Verbs… Again
`,
        "10": "Native",
      };

      const levelDescription =
        levelDescriptions[ProficiencyLevel] || "Beginner";

      const openaiPrompt = `Please generate a passage in the genre of "${Genre}" about "${Topic}". The passage should be engaging and suitable for a ${levelDescription} Spanish learner and should only contain Spanish text. No English.

Please make an effort to include as many of the following Spanish words as possible: ${vocabularyList.join(", ")}.

Include exactly four questions about the passage. For each question, provide:
1. The question text.
2. Exactly four multiple-choice options, clearly labeling one as the correct answer.
3. Identify the correct answer among the options.

Ensure that the JSON response strictly follows this structure:

{
  "Name": "Passage Title",
  "Description": "Short description of the passage",
  "Passage": "The full text of the passage. Use \\n to indicate line breaks between paragraphs.",
  "Question_1": "First question about the passage",
  "Answer_1": "The correct answer to Question 1",
  "Options_1": [
    "Option A",
    "Option B",
    "Option C",
    "Option D"
  ],
  "Question_2": "Second question about the passage",
  "Answer_2": "The correct answer to Question 2",
  "Options_2": [
    "Option A",
    "Option B",
    "Option C",
    "Option D"
  ],
  "Question_3": "Third question about the passage",
  "Answer_3": "The correct answer to Question 3",
  "Options_3": [
    "Option A",
    "Option B",
    "Option C",
    "Option D"
  ],
  "Question_4": "Fourth question about the passage",
  "Answer_4": "The correct answer to Question 4",
  "Options_4": [
    "Option A",
    "Option B",
    "Option C",
    "Option D"
  ]
}

**Important Instructions:**
- **Use \\n** to indicate line breaks between paragraphs in the "Passage" field.
- **Exactly four options** must be provided for each question.
- One of the options must **explicitly be the correct answer**.
- The JSON response should **strictly adhere** to the provided structure with no additional text or commentary.
 - ****All text returned should be in Spanish******
- **Output only** the JSON object and nothing else.

Please ensure that all fields are filled appropriately and that the JSON syntax is correct.
`;

      const response = await callOpenAI(openaiPrompt);

      if (response && response.response) {
        const responseText = response.response;

        // Try to parse the JSON from the response
        try {
          const jsonStart = responseText.indexOf("{");
          const jsonEnd = responseText.lastIndexOf("}");
          const jsonString = responseText.substring(jsonStart, jsonEnd + 1);

          const generatedData = JSON.parse(jsonString);

          setFormData((prevData) => ({
            ...prevData,
            ...generatedData,
          }));
        } catch (err) {
          console.error("Error parsing JSON:", err);
          setError("Error parsing response. Please ensure the prompt was clear.");
        }
      } else {
        setError("No response from OpenAI. Please try again.");
      }
    } catch (err) {
      console.error("Error generating passage:", err);
      setError("Error generating passage. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let imageUrl = formData.ImageUrl;

    try {
      // Destructure formData to exclude the 'Section', 'Topic', and 'ProficiencyLevel' fields for API
      const { Section, Topic, ProficiencyLevel, ...dataToSubmit } = formData;

      // Set `Level` to the selected `Lesson` (which is in Section)
      dataToSubmit.Level = formData.Section;

      // Send the dataToSubmit to the API without the 'Section', 'Topic', and 'ProficiencyLevel' fields
      await addPassage({
        ...dataToSubmit,
        ImageUrl: imageUrl,
      });

      // Add 'Section', 'Topic', and 'ProficiencyLevel' back when updating local state to match the TableData interface
      setTableData((prevData) => [
        {
          ...dataToSubmit,
          Section: formData.Section,
          Topic: formData.Topic,
          ProficiencyLevel: formData.ProficiencyLevel,
          ImageUrl: imageUrl,
        },
        ...prevData,
      ]);

      setError(null);

      // Reset the form
      setFormData({
        ...initialFormData,
        Level: formData.Level,
        Section: formData.Section,
      });
    } catch (err: any) {
      console.log("Error:", err);
      setError(err.message);
    }
  };

  const handleEdit = (identifier: string) => {
    setEditingRow(identifier);
  };

  const handleEditSubmit = async (identifier: string, level: string) => {
    const itemToUpdate = tableData.find(
      (item) => item.Identifier === identifier
    );

    if (!itemToUpdate) {
      setError("Item not found for editing");
      return;
    }

    try {
      const { Section, Topic, ProficiencyLevel, ...updatedData } = itemToUpdate; // Exclude the Section, Topic, and ProficiencyLevel fields

      await updatePassage(identifier, level, updatedData);

      setEditingRow(null);

      setTableData((prevData) =>
        prevData.map((item) =>
          item.Identifier === identifier ? { ...item, ...updatedData } : item
        )
      );
    } catch (err: any) {
      console.log("Error:", err);
      setError(err.message);
    }
  };

  const handleDelete = async (identifier: string, level: string) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await deletePassage(identifier, level);

        setTableData((prevData) =>
          prevData.filter(
            (item) => item.Identifier !== identifier || item.Level !== level
          )
        );
      } catch (err: any) {
        console.log("Error:", err);
        setError(err.message);
      }
    }
  };

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    identifier?: string
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Optionally, you can open the cropping dialog here if you want to allow cropping for existing items
      // For simplicity, we'll handle image uploads only during passage creation
      if (!identifier) {
        setImageFile(file);
        setCroppingDialogOpen(true);
      } else {
        // Handle image upload for editing existing items
        // Similar to handleCrop, but for existing items
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const fileBlob = new Blob([reader.result as ArrayBuffer], {
              type: file.type,
            });
            const fileExtension = file.name.substring(
              file.name.lastIndexOf(".")
            );
            const newFileName = `${formData.Name.replace(
              /\s+/g,
              "_"
            )}_Image_${uuidv4()}${fileExtension}`;
            setIsUploadingImage(true);
            const imageUrl = await uploadImage(newFileName, fileBlob);
            setTableData((prevData) =>
              prevData.map((item) =>
                item.Identifier === identifier
                  ? { ...item, ImageUrl: imageUrl }
                  : item
              )
            );
            setIsUploadingImage(false);
          } catch (err: any) {
            console.error("Error uploading image:", err);
            setError("Error uploading the image. Please try again.");
            setIsUploadingImage(false);
          }
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const renderCellValue = (value: any) => {
    if (Array.isArray(value)) {
      return value.join(", ");
    }

    return String(value);
  };

  const renderFormFields = () => {
    const fields = Object.entries(formData).filter(
      ([key]) =>
        ![
          "Identifier",
          "Level",
          "Section",
          "ProficiencyLevel",
          "Topic",
          "ImageUrl",
        ].includes(key)
    );

    return fields.map(([key, value]) => {
      if (Array.isArray(value)) {
        return (
          <div key={key} className={styles.formGroup}>
            <label className={styles.label}>{key}:</label>
            {value.map((opt, idx) => (
              <input
                key={`${key}_${idx}`}
                type="text"
                name={`${key}_${idx}`}
                value={opt}
                onChange={handleChange}
                className={styles.input}
              />
            ))}
          </div>
        );
      } else if (key === "Passage") {
        return (
          <div key={key} className={styles.formGroup}>
            <label className={styles.label}>{key}:</label>
            <textarea
              name={key}
              value={value}
              onChange={handleChange}
              className={styles.input}
              rows={6}
            />
          </div>
        );
      } else {
        return (
          <div key={key} className={styles.formGroup}>
            <label className={styles.label}>{key}:</label>
            <input
              type="text"
              name={key}
              value={value}
              onChange={handleChange}
              className={styles.input}
            />
          </div>
        );
      }
    });
  };

  return (
    <div className={styles.container}>
      <button onClick={() => navigate("/")} className={styles.backButton}>
        &larr; Back to Home
      </button>

      <h1 className={styles.title}>Passages</h1>

      <div className={styles.form}>
        <div className={styles.topRight}>
          <label className={styles.label}>
            Level:
            <select
              name="Level"
              value={formData.Level}
              onChange={handleChange}
              className={styles.input}
            >
              {[...Array(150).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </label>

          <label className={styles.label}>
            Section:
            <input
              type="text"
              name="Section"
              value={formData.Section}
              readOnly
              className={styles.input}
            />
          </label>
        </div>
      </div>

      <h2>Add New Passage</h2>

      <form onSubmit={handleSubmit} className={styles.form}>
        {/* Proficiency Level Dropdown */}
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Proficiency Level:
            <select
              name="ProficiencyLevel"
              value={formData.ProficiencyLevel}
              onChange={handleChange}
              className={styles.input}
            >
              {[...Array(10).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </label>
        </div>

        {/* Genre Input */}
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Genre:
            <input
              type="text"
              name="Genre"
              value={formData.Genre}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </label>
        </div>

        {/* Topic Field with Generate Random Topic Button */}
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Topic:
            <input
              type="text"
              name="Topic"
              value={formData.Topic}
              readOnly
              className={styles.input}
            />
          </label>
          <button
            type="button"
            onClick={handleGenerateRandomTopic}
            className={styles.button}
            disabled={isGeneratingTopic}
          >
            {isGeneratingTopic ? "Generating..." : "Generate Random Topic"}
          </button>
        </div>

        {/* Generate Passage Button */}
        <button
          type="button"
          onClick={handleGeneratePassage}
          className={styles.button}
          disabled={isGenerating}
        >
          {isGenerating ? "Generating..." : "Generate Passage"}
        </button>

        {/* Dynamically Rendered Form Fields */}
        {renderFormFields()}

        {/* Image Upload */}
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Image:
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className={styles.input}
            />
          </label>
        </div>

        {/* Image Preview */}
        {isUploadingImage && <p>Uploading image...</p>}
        {formData.ImageUrl && (
          <img
            src={formData.ImageUrl}
            alt="Uploaded"
            className={styles.imagePreview}
          />
        )}

        {/* Submit Button */}
        <button
          type="submit"
          className={styles.button}
          disabled={isUploadingImage}
        >
          Add Passage
        </button>
      </form>

      {/* Table displaying the passages */}
      <table className={styles.table}>
  <thead>
    <tr>
      {Object.keys(initialFormData).map((key) => (
        <th key={key}>{key}</th>
      ))}
      <th>Edit / Delete</th>
    </tr>
  </thead>
  <tbody>
    {tableData.map((item, index) => (
      <tr key={index}>
        {Object.keys(initialFormData).map((key) => {
          const value = item[key as keyof TableData];


          if (key === "ImageUrl") {
            return (
              <td key={key}>
                {editingRow === item.Identifier ? (
                  <>
                    <label
                      htmlFor={`image-upload-${index}`}
                      className={styles.imageUploadLabel}
                    >
                      Upload Image
                      <input
                        id={`image-upload-${index}`}
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                          handleImageChange(e, item.Identifier)
                        }
                        className={styles.imageUploadInput}
                      />
                    </label>
                    {item.ImageUrl && (
                      <img
                        src={item.ImageUrl}
                        alt="Preview"
                        className={styles.imagePreview}
                      />
                    )}
                  </>
                ) : item.ImageUrl ? (
                  <img
                    src={item.ImageUrl}
                    alt={item.Name}
                    className={styles.imagePreview}
                  />
                ) : (
                  "No Image"
                )}
              </td>
            );
          } else if (Array.isArray(value)) {
            return (
              <td key={key}>
                {editingRow === item.Identifier
                  ? value.map((opt, idx) => (
                      <input
                        key={`${key}_${idx}`}
                        type="text"
                        name={`${key}_${idx}`}
                        value={opt}
                        onChange={(e) =>
                          handleChange(e, item.Identifier)
                        }
                        className={styles.input}
                      />
                    ))
                  : renderCellValue(value)}
              </td>
            );
          } else if (key === "Passage") {
            return (
              <td key={key}>
                {editingRow === item.Identifier ? (
                  <textarea
                    name={key}
                    value={value}
                    onChange={(e) => handleChange(e, item.Identifier)}
                    className={styles.textarea}
                  />
                ) : (
                  <div style={{ whiteSpace: "pre-wrap" }}>{value}</div>
                )}
              </td>
            );
          } else {
            return (
              <td key={key}>
                {editingRow === item.Identifier ? (
                  <input
                    type="text"
                    name={key}
                    value={value}
                    onChange={(e) => handleChange(e, item.Identifier)}
                    className={styles.input}
                  />
                ) : (
                  value
                )}
              </td>
            );
          }
        })}

        {/* Edit / Delete Buttons */}
        <td>
          {editingRow === item.Identifier ? (
            <>
              <div className={styles.buttonWrapper}>
                <button
                  onClick={() =>
                    handleEditSubmit(item.Identifier, item.Level)
                  }
                  className={styles.button}
                >
                  Submit
                </button>
              </div>
              <div className={styles.buttonWrapper}>
                <button
                  onClick={() => setEditingRow(null)}
                  className={styles.button}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <div className={styles.buttonWrapper}>
                <button
                  onClick={() => handleEdit(item.Identifier)}
                  className={styles.button}
                >
                  Edit
                </button>
              </div>
              <div className={styles.buttonWrapper}>
                <button
                  onClick={() =>
                    handleDelete(item.Identifier, item.Level)
                  }
                  className={styles.button}
                >
                  Delete
                </button>
              </div>
            </>
          )}
        </td>
      </tr>
    ))}
  </tbody>
</table>

      {/* Cropping Modal */}
      <Modal
        isOpen={croppingDialogOpen}
        onRequestClose={() => setCroppingDialogOpen(false)}
        contentLabel="Crop Image"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        {imageFile && (
          <div className={styles.cropContainer}>
            <Cropper
              image={URL.createObjectURL(imageFile)}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />

            <div className={styles.cropControls}>
              <input
                type="range"
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(Number(e.target.value))}
                className={styles.zoomSlider}
              />
              <button
                onClick={handleCrop}
                className={`${styles.button} ${styles.cropButton}`}
                disabled={isUploadingImage}
              >
                {isUploadingImage ? "Uploading..." : "Crop & Upload Image"}
              </button>
            </div>
          </div>
        )}
      </Modal>

      {error && <div className={styles.error}>Error: {error}</div>}
    </div>
  );
};

export default TableDetails;
