import React, { useState } from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import styles from './Metrics.module.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const ChatterboxMetrics: React.FC = () => {
  const [timeframe, setTimeframe] = useState('weekly');

  // Sample data for logins
  const loginData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Paid Users',
        data: [20, 30, 40, 50],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: 'Free Users',
        data: [15, 25, 35, 45],
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
      },
      {
        label: 'Total',
        data: [35, 55, 75, 95],
        borderColor: 'rgba(255, 159, 64, 1)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
      },
    ],
  };

  // Sample data for average time spent on app
  const timeSpentData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Paid Users',
        data: [60, 70, 80, 90],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: 'Free Users',
        data: [50, 60, 70, 80],
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
      },
      {
        label: 'Total',
        data: [110, 130, 150, 170],
        borderColor: 'rgba(255, 159, 64, 1)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
      },
    ],
  };

  // Sample data for effectiveness of notifications
  const notificationEffectivenessData = {
    labels: ['Opened', 'Ignored'],
    datasets: [
      {
        label: 'Notification Effectiveness',
        data: [70, 30],
        backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)'],
      },
    ],
  };

  // Sample data for last page used before exiting app
  const lastPageUsedData = {
    labels: ['Home', 'Profile', 'Settings', 'Lesson', 'Quiz'],
    datasets: [
      {
        label: 'Lesson 1',
        data: [5, 3, 2, 7, 8],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
      {
        label: 'Lesson 2',
        data: [3, 2, 1, 5, 6],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Lesson 3',
        data: [2, 3, 1, 4, 5],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  // Sample data for user sources as a line chart
  const userSourcesData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Direct',
        data: [15, 20, 25, 30],
        borderColor: 'rgba(255, 206, 86, 1)',
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
      },
      {
        label: 'Social Media',
        data: [10, 15, 10, 20],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: 'Referral',
        data: [5, 10, 15, 20],
        borderColor: 'rgba(255, 159, 64, 1)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
      },
      {
        label: 'Organic Search',
        data: [20, 25, 30, 35],
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
      },
      {
        label: 'Paid Ads',
        data: [25, 30, 35, 40],
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
    ],
  };

  // Sample data for quiz scores
  const quizScoresData = {
    labels: ['Quiz 1', 'Quiz 2', 'Quiz 3', 'Quiz 4'],
    datasets: [
      {
        label: 'Average Score',
        data: [85, 78, 92, 88],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };

  // Sample data for time to reach objective
  const timeToObjectiveData = {
    labels: ['Objective 1', 'Objective 2', 'Objective 3', 'Objective 4'],
    datasets: [
      {
        label: 'Time (minutes)',
        data: [30, 45, 35, 40],
        borderColor: 'rgba(255, 159, 64, 1)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
      },
    ],
  };

  // Sample data for course progression speed
  const courseProgressionData = {
    labels: ['Module 1', 'Module 2', 'Module 3', 'Module 4'],
    datasets: [
      {
        label: 'Days to Complete',
        data: [10, 12, 8, 15],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          color: '#e94560', // Change this color to your desired label color
          font: {
            size: 14, // Adjust the size as needed
          },
        },
      },
      tooltip: {
        bodyColor: '#e94560', // Change this color to your desired tooltip label color
        titleColor: '#e94560', // Change this color to your desired tooltip title color
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#e94560', // Change this color to your desired x-axis label color
        },
      },
      y: {
        ticks: {
          color: '#e94560', // Change this color to your desired y-axis label color
        },
      },
    },
  };
  

  const handleTimeframeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeframe(event.target.value);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Chatterbox Metrics</h1>
      <div className={styles.timeframeSelector}>
        <label htmlFor="timeframe">Select Timeframe: </label>
        <select id="timeframe" value={timeframe} onChange={handleTimeframeChange}>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>
      <div className={styles.chartContainer}>
        <div className={styles.chart}>
          <h2 className={styles.chartTitle}>Log Ins</h2>
          <Line data={loginData} options={options} />
        </div>
        <div className={styles.chart}>
          <h2 className={styles.chartTitle}>Average Time Spent on App</h2>
          <Line data={timeSpentData} options={options} />
        </div>
        <div className={`${styles.chart} ${styles.pieChart}`}>
          <h2 className={styles.chartTitle}>Effectiveness of Notifications</h2>
          <Pie data={notificationEffectivenessData} options={options} />
        </div>
        <div className={styles.chart}>
          <h2 className={styles.chartTitle}>Last Page Used Before Exiting</h2>
          <Bar
            data={lastPageUsedData}
            options={{ ...options, scales: { x: { stacked: true }, y: { stacked: true } } }}
          />
        </div>
        <div className={styles.chart}>
          <h2 className={styles.chartTitle}>Where Users Are Coming From</h2>
          <Line data={userSourcesData} options={options} />
        </div>
        <div className={styles.chart}>
          <h2 className={styles.chartTitle}>Quiz Scores</h2>
          <Line data={quizScoresData} options={options} />
        </div>
        <div className={styles.chart}>
          <h2 className={styles.chartTitle}>Time to Reach Objective</h2>
          <Bar data={timeToObjectiveData} options={options} />
        </div>
        <div className={styles.chart}>
          <h2 className={styles.chartTitle}>Course Progression Speed</h2>
          <Line data={courseProgressionData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default ChatterboxMetrics;
